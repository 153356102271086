<template>
  <div id="app" class="menu">
    <h1 v-on:click="toggle25">2025</h1>
    <transition name="slide">
      <TwentyFiveArticles v-if="show25"/>
    </transition>
    <hr>
    <h1 v-on:click="toggle24">2024</h1>
    <transition name="slide">
      <TwentyFourArticles v-if="show24"/>
    </transition>
    <hr>
  </div>
</template>

<script>
import TwentyFourArticles from '@/components/partials/articles/TwentyFourArticles.vue'
import TwentyFiveArticles from '@/components/partials/articles/TwentyFiveArticles.vue'

export default {
  name: 'App',
  components: {
    TwentyFourArticles,
    TwentyFiveArticles
  },
    data() {
      return {
        show24: false,
        show25: false
      }
    },
    methods: {
      toggle24() {
        if (this.show24 === false) {
          this.show24 = true
        } else {
          this.show24 = false
        }
      },
      toggle25() {
        if (this.show25 === false) {
          this.show25 = true
        } else {
          this.show25 = false
        }
      }
    }
}
</script>


<style>


@media only screen and (max-width: 767px) {
  .article {
    max-width: 80%;
    min-width: 80%;
    margin: 0 auto;
  }

  .article p {
    max-width: 100%;
    min-width: 100%;
    margin: 0 auto;
  }

  .title {
    width: 100%; /* Ensure the title div takes up full width */
    background: #434342;
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

h1 {
  color: white;
}

.title .main {
    min-width: 100%; /* Ensure the title div takes up full width */
    background: #434342;
    padding-bottom: 20px;
}

.subheadings {
    display: flex; /* Enable Flexbox */
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Align items vertically in the middle */
    width: 100%; /* Ensure subheadings take up full width */
}

.subheadings p {
    margin: 0 5px; /* Add some space between the p elements */
    display: inline-block; /* This is optional since flex items are inline-level by default */
    text-align: center;
    font-size: 14px !important;
    background: #434342;
}

.published-date {
  color: white !important;
}

.affiliate-link {
  color: #FCBA03 !important;
}

.title p.published-date,
.title p.affiliate-link {
    padding: 4px; /* Removes default margin from p elements */
}

.title {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  width: 100%;
  background: #434342;
  padding-bottom: 20px;
  padding-top: 20px;
}

.title p {
  text-align: center;
  font-size: 30px;
  color: #FCBA03;
  line-height: 125%;
  display: inline-block;
}

.article p {
  font-family: "Lustria", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  background: #434342;
  padding-bottom: 10px;
  padding-top: 10px;
  font-size: 16px;
  margin: auto;
  max-width: 40%;
  color: white;
}

li {
  font-size: 16px;
}

a {
  color: #FCBA03 !important;
}

a:hover {
  color: #ffcf4a !important;
  text-decoration: none !important;
}

hr {
  border-color: white;
  height: 5px;
}

.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.menu {
  margin: auto;
    max-width: 85%;
    margin-top: 60px;
    color: white;
}

</style>
