<template>
    <div>

		<div class="title">
			<p class="main">Yitzhak Rabin: From Soldier to Peacemaker by Libby Hughes</p>
			<div class="subheadings">
				<p class="published-date">7th January 2025</p>
			</div>
		</div>

		<div class="article">
			<p>Right, let this be a lesson that I take forward after a few missteps last year. Check. Authors'. Credentials.</p>

			<p>I take no issue with the veracity of the content here, for clarity. And for such a pivotal figure in recent Middle East peace movements, Yitzhak Rabin is painfully under-covered by serious historians. So basically my choices were this book, or Rabin’s own memoir which he wrote in the 70s. Well before, for me, the most consequential period of his life in the 90s. So I took a gamble but boy did it not pay off. I have basically nothing to say about this book. It’s extremely short and really suffers for it. It reads like a summary from Wikipedia and provides no new information. To  the extent that it’s worth reading, it’s only for the cited sources which I’ll be turning to myself to get some more information than “These are the main things that happened in his life and come up if you Google him”. No insights. No interviews. No nothing. The book appears to be self published and it would not shock me if the author’s ChatGPT history contains the prompt “Give me a biography of Yitzhak Rabin and cite some sources”.</p>

			<p>So we’re not off to a great start. I started last year with Ron Chernow’s biography of Washington and it was the level of detail from that book that made me want to write these reviews in the first place. I wanted to try to train myself to remember better what I’d read and what I learned from it. In this case, I could have sat quietly in a dark room with my eyes closed, remembered that I know that Rabin signed the Oslo Accords and was shot, and I’d have had the same basic experience. I paid currency for this book. A book I could conceivably have written in the time it took me to read it and write this review. So here’s my advice: Check the author out. Do they seem like they churn out pulp at a rate of knots? Check the publisher. Is it Amazon? Go to a book shop. They will know better than the algorithm what you’re looking for.</p>

			<p>One other thing I should say here is that I was a bit overwhelmed by how much reading I took on last year. I’m a total completionist so if I put a list of books up for the year, I will need to finish them. That got the better of me last year and because I have my own demons it slightly ruined the end of my year. So to prevent that, I’ve massively cut back on my reading this year. I’m also expecting to have a significantly busier work year which will give less time for reading. But on the other hand, I am learning piano this year. And I’ve got a decent number of guitar recordings finished now (all of them terrible, and horribly produced) so I may stick some of them up. That’ll be taking a lot of my learning/recreation time this year so I should try to have something to show for it.</p>

			<p>Don’t buy this book. Buy a good one instead.</p>
		</div>


    </div>
</template>


<script>

	export default {
		name: "YitzhakRabinArticle"
	}

</script>


<style>

</style>