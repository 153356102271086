<template>
    <div>

		<div class="title">
			<p class="main">James by Percival Everett</p>
			<div class="subheadings">
				<p class="published-date">20th January 2025</p>
				
				
			</div>
		</div>

		<div class="article">
			<p>Well now we’re on a roll. I watched American Fiction last year. A really, really outstanding film based on Erasure, also by Percival Everett, the author of James. Spoiler free, American Fiction tells the story of an accomplished academic and writer who dreams of writing a popular novel and getting recognition for his genius. He is continually turned away for not writing “black books” that speak to a stereotypical working-class Black American experience. His books about middle class Black family struggles don’t chime with what people expect of him and American Fiction covers his attempts to get over that.</p>

			<p>It’s impossible not to think American Fiction (or more accurately Erasure) has some basis in reality when reading James. This is a book that absolute fucking fury screams out of from every page. It’s like Everett wants to grab you by the scruff of the neck and say “You total fucking idiot! Pay attention! Consider things critically for more than a microsecond!”. James tells the story of The Adventures of Huckleberry Finn but from the character Jim’s perspective. The idea seems almost obvious in hindsight and maybe it’s my naivety but I was totally dumbstruck by how brilliantly chosen every single word is from the first page. It should go without saying that Everett is a brilliant writer but crucial elements like the level of code-switching that the slaves in the book do, to how the plot deviates from Huck Finn and where it sticks to it, to his ability to make the original book seem trite and simplistic just elevate this to a whole other level. I loved Orbital (below) and count it among the best books I’ve read. James is different. I don’t know if it’s better but it’s competitive.</p>

			<p>I get the feeling that Everett’s point was to make Huck Finn feel like a kids book (which in fairness Twain always maintained it was). The original is held up as a bastion of anti-slavery literature and let’s not forget that there the abolitionists are the goodies and the slavers are the baddies. But the language lets it down. Obviously Jim is not called simply Jim in Huck Finn and the choice here to use the full James is not especially subtle but it’s more powerful than you might think despite that. James portrays Huck as something of a victim of his time and it certainly has some idiosyncrasies with the actual period and the original book, but the overall point (I assume) and effect is that you feel like you’re reading the actual story having only ever heard it through someone else. In Everett’s hands, the “adventures” of Huck become the life or death nightmare of James. Huck Finn tells a fun story in which some characters oppose slavery. James is stressful to read and each character's own positions are rarely either known or relevant.</p>

			<p>I finished James as I travelled home on the day of Donald Trump’s second inauguration. I don’t know what’s going to happen in the next four years but I know who is afraid. And that’s what I’m paying attention to. James is a lesson in that, really. There’s a saying that one should vote in the interests of the most vulnerable person you know. I think that’s a good guideline but it can sometimes lead to short term-ism. If you had applied that at the outbreak of the Civil War in America you wouldn’t have voted for Lincoln or any abolitionist candidate. The feeling was that electing an abolitionist would lead to war - which was correct. The actual war itself helped no one and many in that movement felt that a compromise where slaves could more easily escape or be freed would be a good incremental step. I’m often in favour of incremental steps. I’d rather have something a bit better than nothing as a bit of a rule. But James shows us that if the evil is big enough, it doesn’t really matter what increments you can make up. If the problem is systemic, like slavery, there is no incremental way to a solution because it will always be opposed at the final step. So you need to rip off the bandage sometimes.</p>

			<p>James the proper story of a slave in the mid 19th century that Mark Twain neither wrote nor set out to write. It <i>does</i> make Huck Finn seem childish and almost pointless. Whether you’ve read Huck Finn or not, you should read James. It is outstanding. And I’ll be buying Percival Everett’s entire back catalogue before this is even published.</p>
		</div>


    </div>
</template>


<script>

	export default {
		name: "JamesArticle"
	}

</script>


<style>

</style>