<template>
  <div>
    <LetrangerArticle/>
    <GreatExpectationsArticle/>
    <ProphetSongArticle/>
    <TeamOfRivalsArticle/>
    <TomorrowAndTomorrowAndTommorowArticle/>
    <MurderousContagionArticle/>
    <InvertingThePyramidArticle/>
    <NoiseArticle/>
    <FranceArticle/>
    <NudgeArticle/>
    <OurLastBestChanceArticle/>
    <TheLionAndTheUnicornArticle/>
    <EscapeArticle/>
    <NumbercrunchArticle/>
    <FourWaysOfThinkingArticle/>
    <ExpectedGoalsArticle/>
    <PhantomPlagueArticle/>
    <UnreasonableHospitalityArticle/>
    <BronzeAgeArticle/>
    <WashingtonArticle/>
  </div>
</template>

<script>
  import WashingtonArticle from '@/components/partials/articles/twentyfour/WashingtonArticle.vue'
  import BronzeAgeArticle from '@/components/partials/articles/twentyfour/BronzeAgeArticle.vue'
  import UnreasonableHospitalityArticle from '@/components/partials/articles/twentyfour/UnreasonableHospitalityArticle.vue'
  import PhantomPlagueArticle from '@/components/partials/articles/twentyfour/PhantomPlagueArticle.vue'
  import ExpectedGoalsArticle from '@/components/partials/articles/twentyfour/ExpectedGoalsArticle.vue'
  import FourWaysOfThinkingArticle from '@/components/partials/articles/twentyfour/FourWaysOfThinkingArticle.vue'
  import NumbercrunchArticle from '@/components/partials/articles/twentyfour/NumbercrunchArticle.vue'
  import EscapeArticle from '@/components/partials/articles/twentyfour/EscapeArticle.vue'
  import TheLionAndTheUnicornArticle from '@/components/partials/articles/twentyfour/TheLionAndTheUnicornArticle.vue'
  import OurLastBestChanceArticle from '@/components/partials/articles/twentyfour/OurLastBestChanceArticle.vue'
  import NudgeArticle from '@/components/partials/articles/twentyfour/NudgeArticle.vue'
  import FranceArticle from '@/components/partials/articles/twentyfour/FranceArticle.vue'
  import NoiseArticle from '@/components/partials/articles/twentyfour/NoiseArticle.vue'
  import InvertingThePyramidArticle from '@/components/partials/articles/twentyfour/InvertingThePyramidArticle.vue'
  import MurderousContagionArticle from '@/components/partials/articles/twentyfour/MurderousContagionArticle.vue'
  import TomorrowAndTomorrowAndTommorowArticle from '@/components/partials/articles/twentyfour/TomorrowAndTomorrowAndTommorowArticle.vue'
  import TeamOfRivalsArticle from '@/components/partials/articles/twentyfour/TeamOfRivalsArticle.vue'
  import ProphetSongArticle from '@/components/partials/articles/twentyfour/ProphetSongArticle.vue'
  import GreatExpectationsArticle from '@/components/partials/articles/twentyfour/GreatExpectationsArticle.vue'
  import LetrangerArticle from '@/components/partials/articles/twentyfour/LetrangerArticle.vue'


  export default {
  name: 'App',
  components: {
    WashingtonArticle,
    BronzeAgeArticle,
    UnreasonableHospitalityArticle,
    PhantomPlagueArticle,
    ExpectedGoalsArticle,
    FourWaysOfThinkingArticle,
    NumbercrunchArticle,
    EscapeArticle,
    TheLionAndTheUnicornArticle,
    OurLastBestChanceArticle,
    NudgeArticle,
    FranceArticle,
    NoiseArticle,
    InvertingThePyramidArticle,
    MurderousContagionArticle,
    TomorrowAndTomorrowAndTommorowArticle,
    TeamOfRivalsArticle,
    ProphetSongArticle,
    GreatExpectationsArticle,
    LetrangerArticle
  }
}
</script>