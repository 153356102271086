<template>
  <div>
    <JamesArticle/>
    <OrbitalArticle/>
    <YitzhakRabinArticle/>
  </div>
</template>

<script>
  import JamesArticle from '@/components/partials/articles/twentyfive/JamesArticle.vue'
  import OrbitalArticle from '@/components/partials/articles/twentyfive/OrbitalArticle.vue'
  import YitzhakRabinArticle from '@/components/partials/articles/twentyfive/YitzhakRabinArticle.vue'



  export default {
  name: 'App',
  components: {
    JamesArticle,
    OrbitalArticle,
    YitzhakRabinArticle 
  }
}
</script>