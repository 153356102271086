<template>
    <div>

		<div class="title">
			<p class="main">Orbital by Samantha Harvey</p>
			<div class="subheadings">
				<p class="published-date">10th January 2025</p>
				
				
			</div>
		</div>

		<div class="article">
			<p>Ah that’s better. From the first page, Samantha Harvey’s Booker Prize Winner, Orbital, is like slipping into a warm bath. One of the best books I’ve ever read. The book tells the story of astronauts and cosmonauts aboard the International Space Station over the course of 24 hours. I hesitate to say “over the course of a day” because as the novel points out repeatedly there is kind of no such thing as a day on the ISS. Space doesn’t respect human calendars and the astronauts see a sunrise and sunset every 90 minutes. This repetition is a theme that comes up again and again with each orbit (or chapter) giving the chance for a new reflection from one of many of the astronauts on some aspect of their life in space, their life at home, their smallness, or their bigness.</p>

			<p>I’m reading a bit more fiction this year and I want to avoid spoilers as much as possible but suffice it to say that not an awful lot happens. This is a character study and a look at what it means to be near (the ISS orbits at about 250 miles) and far (I won’t explain that space is far away). The things that do happen have stakes, don’t get me wrong. But this is not a plot driven book. It’s a book of great lines of prose that make you sit up and say “Yes, that’s right, it is brilliant” or “it is terrible” and pledge to pay a bit more attention.</p>

			<p>Harvey makes reference to both the <a href="https://en.wikipedia.org/wiki/Overview_effect">overview effect</a>, and the <a href="https://en.wikipedia.org/wiki/Cosmic_Calendar">cosmic calendar</a>. Some reviewers online have said that these are trite references but I don’t agree. They’re exactly what I think about when I consider what it would be like to live for a while on the International Space Station. Even the name is incredible. There’s a line from an <a href="https://www.nytimes.com/2017/03/13/style/amy-krouse-rosenthal-dies-modern-love.html">Amy Krouse Rosenthal</a> book I think about a lot. She says that in a given life, there is a limit to the number of times you get to look at a tree. It may be in the tens of thousands, but it is not infinite. And that anything less than infinite feels too small. Orbital made me feel the way it makes me feel to consider the fact that I have a lot of trees left to look at, but not an infinite number of times.</p>

			<p>A constant thread through the book is the moon landing in 1969, and another moon landing taking place in the same year as the book does. In this case it appears to be a billionaire play thing that’s going to the moon rather than some national endeavour. Which strikes me as a shame. Not to mention the fact that someone who can put together a feasible plan to go to the moon probably has more money than they need. I’m not talking about anyone in particular. I’m just saying. The original mood landings in the late 60s and early 70s are the kind of glorious task that Harvey’s book is trying to evoke. I don’t want to sound cynical but <a href="https://www.wired.com/2016/01/jeff-bezos-blue-origin-rocket-took-off-and-landed-again/">Jeff Bezos’ dick-mobile</a> doesn’t exactly get me feeling that space is the final frontier. Orbital did though.</p>

			<p>I’m writing this as we see wildfires ravage Los Angeles. Part of the book reflects on a typhoon near the Philippines and Orbital seeks to give us pause about the fragility of the Earth. I don’t know what’s going to happen to the climate in the next 50 years but I’ll say this much - I doubt we’ve seen the last of the wildfires. We definitely, though, will see the last of the ISS. At the end of this decade, the space station will de-orbit leaving humanity without a permanent presence off of our planet. All good things must come to an end, and I understand the reservations about spending billions on going to space when so much attention is required at home but I still want us to be in space. I want us to be on the moon and on Mars. I just want us to do it in a way that makes us genuinely proud.</p>

			<p>If the first words on Mars are Elon Musk saying “the wifi here sucks 🤣” I’ll probably just kill myself.</p>
		</div>


    </div>
</template>


<script>

	export default {
		name: "OrbitalArticle"
	}

</script>


<style>

</style>